import service from '@/utils/request'; // @Tags EquityCoupon
// @Summary 创建EquityCoupon
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityCoupon true "创建EquityCoupon"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/coupon [post]

export var createEquityCoupon = function createEquityCoupon(data) {
  return service({
    url: "/resource/coupon",
    method: 'post',
    data: data
  });
}; // @Tags EquityCoupon
// @Summary 更新EquityCoupon
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityCoupon true "更新EquityCoupon"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /resource/coupon [post]

export var updateEquityCoupon = function updateEquityCoupon(data) {
  return service({
    url: "/resource/coupon",
    method: 'put',
    data: data
  });
}; // @Tags EquityCoupon
// @Summary 分页获取EquityCoupon列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityCoupon列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resource/coupon [get]

export var getEquityCouponList = function getEquityCouponList(params) {
  // params = { goods_id: -1, ...params }
  return service({
    url: "/resource/coupon",
    method: 'get',
    params: params
  });
};